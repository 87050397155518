import { Ability, AbilityBuilder } from '@casl/ability'
import { store } from '../store'

const ability = new Ability()

const CAN = (action, subject) => {
  return ability.can(action, subject)
}

let currentAuth = {}
store.subscribe(() => {
  const prevAuth = currentAuth
  currentAuth = store.getState().auth
  if (prevAuth !== currentAuth) {
    ability.update(defineRulesFor(currentAuth))
  }
})

const defineRulesFor = (auth) => {
  const permissions = auth.permissions
  const { can, rules } = new AbilityBuilder()
  // This logic depends on how the
  // server sends you the permissions array

  if (permissions) {
    permissions
      .map((permission) => permission.name)
      .forEach((permission) => {
        let splitPermission = permission.split('-')
        can(splitPermission[0], splitPermission[1])
      })
  }
  return rules
}
export default CAN
