export const API_BASE_URL = process.env.REACT_APP_SERVER_URL
export const ACCESS_TOKEN_NAME = 'api_token'
export const PERSIST_ROOT = 'persist:root'
export const CRYPT_SALT = 'KEY-4-3DM'
export const REACT_APP_IMAGE_URL = 'https://imagedelivery.net/XovtbYyxEpukM4OnNtY6Nw/'
export const maxLength = 50
export const minNumber = 0
export const maxNumber = 99999999
export const MAX_LENGTH = 50
export const EMAIL_MAX_LENGTH = 100
export const URL_MAX_LENGTH = 500
export const DESCRIPTION_MAX_LENGTH = 100
export const API_KEY_MAX_LENGTH = 100
export const MIN_NUMBER = 0
export const MAX_NUMBER = 99999999
export const NUMBER_MINUS_ONE = -1
export const NUMBER_ZERO = 0
export const NUMBER_ONE = 1
export const NUMBER_TWO = 2
export const NUMBER_THREE = 3
export const NUMBER_FOUR = 4
export const NUMBER_FIVE = 5
export const NUMBER_SIX = 6
export const NUMBER_TEN = 10
export const NUMBER_TWELVE = 12
export const NUMBER_FIFTEEN = 15
export const NUMBER_TWENTY = 20
export const NUMBER_FIFTY = 50
export const NUMBER_HUNDRED = 100
export const NUMBER_TWO_THREE_TIMES = 222
export const NUMBER_THOUSAND = 1000
export const NUMBER_NINE_FOUR_TIMES = 9999
export const EMPTY_STRING = ''
export const EMPTY_SPACE = ' '
export const SELECT_ONE = 'select_one'
export const IMAGE = 'image'
export const ITEMS = 'items'
export const SUCCESS = 'success'
export const ACTION_SUCCESS = 'action_success'
export const ACTION_FAIL = 'action_fail'
export const DANGER = 'danger'
export const COMMON = 'common'
export const CLIENT_ID = 'client_id'
export const CLIENT = 'client'
export const ITEM_TYPE_ID = 'item_type_id'
export const SKU = 'sku'
export const DESCRIPTION = 'description'
export const HAZMAT = 'hazmat'
export const CHECKBOX = 'checkbox'
export const UOM_TYPE_ID = 'uom_type_id'
export const QUANTITY = 'quantity'
export const BARCODE = 'barcode'
export const MIX_LOT = 'mix_lot'
export const LOT_KIT = 'lot_kit'
export const UOM_FIELDS = 'uom_fields'
export const DUPLICATE_UOM = 'duplicate_uom'
export const ALL = 'all'
export const ERROR_COLON = 'error:'
export const MULTIPART_FORM_DATA = 'multipart/form-data'
export const ITEM_EDIT_FORM = 'item_edit_form'
export const ITEM_CREATE_FORM = 'item_create_form'
export const GENERAL_INFO = 'general_info'
export const PRODUCT_DETAILS = 'product_details'
export const MANUFACTURER_DETAILS = 'manufacturer_details'
export const UOM_STEP = 'uom'
export const ATTRIBUTES = 'attributes'
export const KIT_COMPONENT = 'kit_component'
export const LOG = 'log'
export const SUBMIT = 'submit'
export const BACK = 'back'
export const BACK_CONFIRMATION = 'back_confirmation'
export const PRIMARY = 'primary'
export const ACTION = 'action'
export const SYNCHRONISATION = 'synchronisation'
export const FLOAT_END = 'float-end'
export const QUESTION_MARK = '?'
export const SLASH = '/'
export const ADD_NEW_ITEM = 'add_new_item'
export const SELECT = 'select'
export const IMG = 'img'
export const TYPE = 'type'
export const ALPHABET_S = 's'
export const AVAILABLE = 'available'
export const RESERVED = 'reserved'
export const ALLOCATED = 'allocated'
export const RECEIVING = 'receiving'
export const TOTAL = 'total'
export const DETAILS = 'details'
export const UPLOAD_IMAGE = 'upload_image'
export const PRINT_BARCODE = 'print_barcode'
export const EDIT = 'Edit'
export const REMOVE = 'remove'
export const UN_NO = 'un_no'
export const CLASS = 'class'
export const QUANTITIES = 'quantities'
export const PACKING_GROUP = 'packing_group'
export const DESCRIPTIONS = 'descriptions'
export const ALPHABET_A = 'A'
export const BARCODE_GENERATOR = 'barcode-generator'
export const CLOSE = 'close'
export const PRINT = 'print'
export const UPC_CODE = 'upc_code'
export const SUPPLIER = 'supplier'
export const ORDER_UNIT = 'order_unit'
export const STATUS = 'status'
export const KIT_SURCHARGE = 'kit_surcharge'
export const SUPPLY_HYPHEN_TYPES = 'supply-types'
export const SET_QUARANTINE = 'set_quarantine'
export const SET_PREALERT = 'set_prealert'
export const LOW_LEVEL = 'low_level'
export const UPDATE_IMAGE = 'update_image'
export const PLEASE_CHOOSE_A_PNG_FILE = 'please_choose_a_png_file'
export const SAVE_CHANGES = 'save_changes'
export const KIT_AT_LEAST_ONE = 'kit_at_least_one'
export const SELECT_SKU = 'select_sku'
export const QUANTITY_IN_KIT = 'quantity_in_Kit'
export const ALLOW_TO_MIX_LOTS = 'allow_to_mix_lots'
export const YES = 'Yes'
export const NO = 'No'
export const USE_LOT_FOR_KITS = 'use_lot_for_kits'
export const ADD = 'add'
export const UPDATE = 'update'
export const DELETE_CONFIRM = 'delete_confirm'
export const DELETE = 'delete'
export const NO_DATA = 'no_data'
export const LOCATION = 'location'
export const WAREHOUSE = 'warehouse'
export const QUANTITY_AVAILABLE = 'quantity_available'
export const QUANTITY_RESERVED = 'quantity_reserved'
export const QUANTITY_ALLOCATED = 'quantity_allocated'
export const QUANTITY_RECEIVING = 'quantity_receiving'
export const TOTAL_QUANTITY = 'total_quantity'
export const START = 'start'
export const MID = 'mid'
export const MANUFACTURER_NAME = 'manufacturer_name'
export const COUNTRY = 'country'
export const ORIGINAL_COUNTRY = 'original_country'
export const O_COUNTRY = 'ocountry'
export const CITY = 'city'
export const PROVINCE = 'province'
export const PRO = 'pro'
export const ADDRESS_ONE = 'address1'
export const ADDRESS_TWO = 'address2'
export const POSTAL_CODE = 'postal_code'
export const MANUFACTURER_PHONE = 'manufacturer_phone'
export const CODE = 'code'
export const DEPARTMENT = 'department'
export const SUB_DEPARTMENT = 'sub_department'
export const CATEGORY = 'category'
export const SUB_CATEGORY = 'sub_category'
export const SEASON = 'season'
export const LANGUAGE = 'language'
export const YEAR = 'year'
export const COLOR = 'color'
export const SIZE = 'size'
export const MATERIAL = 'material'
export const PRICE = 'price'
export const CURRENCY = 'currency'
export const BARCODED = 'barcoded'
export const FALSE = 'false'
export const TRUE = 'true'
export const LOT_EXPIRY_NEEDED = 'lot_expiry_needed'
export const COLUMNS_HYPHEN_SELECTED = 'columns-selected'
export const FILTERS = 'filters'
export const SEARCH = 'search'
export const RESET = 'reset'
export const MEASUREMENTS = 'measurements'
export const UOM_AT_LEAST_ONE = 'uom_at_least_one'
export const TOC = 'toc'
export const LENGTH = 'length'
export const WIDTH = 'width'
export const HEIGHT = 'height'
export const WEIGHT = 'weight'
export const GENERATE = 'generate'
export const CONFIGURATION = 'configuration'
export const ACTIONS = 'actions'
export const KGS = 'KGS'
export const JOBS = 'jobs'
export const PDF = 'pdf'
export const SVG = 'svg'
export const MMMM_DD_COMMA_YYYY = 'MMMM DD, yyyy'
export const PACKING_UNDERSCORE_SLIP = 'Packing_Slip'
export const PACKING_UNDERSCORE_SLIPS = 'packaging_slips'
export const JOB_UNDERSCORE_TAG = 'job_tag'
export const PRINT_UNDERSCORE_TAGS = 'print_tags'
export const WARNING = 'warning'
export const A4 = 'A4'
export const A6 = 'A6'
export const SHIP_TO = 'ship_to'
export const DATE = 'date'
export const ORDER_HASH = 'order_hash'
export const BATCH = 'batch'
export const TELEPHONE = 'telephone'
export const EMAIL = 'email'
export const ITEM = 'item'
export const LOT_EXP = 'lot_exp'
export const ORDERED = 'ordered'
export const SHIPPED = 'shipped'
export const UPC = 'upc'
export const THANK_YOU_TEXT = 'thank_you_text'
export const HYPHEN = '-'
export const JOB_ID = 'job_id'
export const JOB_HYPHEN_ID = 'job-id'
export const TICKET = 'ticket'
export const CLIENT_NAME = 'client_name'
export const CLIENT_MIXED = 'client_mixed'
export const ORDERS = 'orders'
export const SKUS = 'skus'
export const LOCATIONS = 'locations'
export const UPDATE_WAREHOUSE = 'update_warehouse'
export const PICKING_METHOD = 'picking_method'
export const ASSIGNED_TO = 'assigned_to'
export const SAVE = 'save'
export const CANCEL = 'cancel'
export const PICKING_HYPHEN_METHOD_HYPHEN = 'picking-method-'
export const DESC = 'desc'
export const TICKET_ID = 'ticket_id'
export const TICKET_TYPE = 'ticket_type'
export const TICKET_TYPE_ID = 'ticket_type_id'
export const START_DATE = 'start_date'
export const START_TIME = 'start_time'
export const JOB_REFERENCE = 'job_reference'
export const COMPLETION = 'completion'
export const PICKING_METHOD_ID = 'picking_method_id'
export const ASSIGNED = 'assigned'
export const COMPLETION_PERCENTAGE = 'completion_percentage'
export const TICKET_STATUS = 'ticket_status'
export const TICKET_STATUS_ID = 'ticket_status_id'
export const WAREHOUSE_ID = 'warehouse_id'
export const ID = 'id'
export const PERCENTAGE = 'percentage'
export const COMPLETED = 'completed'
export const N_A = 'N/A'
export const CHECKED = 'CHECKED'
export const DESTINATION_WAREHOUSE_ID = 'destination_warehouse_id'
export const CHANGE_DESTINATION_WAREHOUSE_WARNING = 'change_destination_warehouse_warning'
export const CHANGE_RECEIVING_STATUS_WARNING = 'change_receiving_status_warning'
export const RECEIVING_PO_STATUS_ID = 'receiving_po_status_id'
export const REPLENISHMENT = 'replenishment'
export const TOP_CENTER = 'top-center'
export const WRONG_LOCATION = 'wrong-location'
export const WRONG_ITEM = 'wrong-item'
export const NOT_REPLENISHMENT = 'not-replenishment'
export const TICKET_COMPLETED = 'ticket-completed'
export const NO_TICKET_FOUND = 'no-ticket-found'
export const WRONG_TOTE = 'wrong-tote'
export const ITEM_ADDED_TOTE = 'item-added-tote'
export const TOTE_IN_USE = 'tote-in-use'
export const LOCATION_NOT_FOUND = 'location-not-found'
export const INVALID_LOCATION = 'invalid-location'
export const INVALID_TOTE = 'invalid_tote'
export const SERVER_ERROR = 'server-error'
export const NAME = 'name'
export const NEEDED = 'needed'
export const PICKED = 'picked'
export const ITEM_IMAGE = 'item-image'
export const PICKING = 'picking'
export const REPLENISHMENT_TRANSFER = 'replenishment_transfer'
export const LOC = 'loc'
export const ITEM_TRANSFER_SUCCESS = 'item_transfer_success'
export const MULTI_CLIENT = 'multi-client'
export const NO_TOTES_AVAILABLE = 'no_totes_available'
export const TOTE_IMAGE = 'tote_image'
export const NEXT_PULLS_REPLENISHMENT = 'next_pulls_replenishment'
export const BEGIN_REPLENISHMENT = 'begin_replenishment'
export const BEGIN_PUT_AWAY = 'begin_put_away'
export const U_PICKED = 'u-picked'
export const QTY_TO_PICK = 'qty_to_pick'
export const QTY_TO_TRANSFER = 'qty_to_transfer'
export const TRANSFER = 'transfer'
export const QUANTITY_EXCEEDS_AVAILABLE_QUANTITY = 'quantity_exceeds_available_quantity'
export const CREATED_BY = 'created_by'
export const UPDATED_BY = 'updated_by'
export const LAST_MODIFIED = 'last_modified'
export const WRONG_ORDER = 'wrong_order'
export const WRONG_ZONE = 'wrong_zone'
export const WRONG_SUPPLY = 'wrong_supply'
export const QTY_SHIPPED = 'qty_shipped'
export const SUPPLY_PICKED_SUCCESSFULLY = 'supply_picked_successfully'
export const SUPPLY_REMOVED_SUCCESSFULLY = 'supply_removed_successfully'
export const CHOOSE_MANUAL_SUPPLIES = 'choose_manual_supplies'
export const SCAN_SUPPLIES = 'scan_supplies'
export const BOXES = 'boxes'
export const VINYLS = 'vinyls'
export const JIFFIES = 'jiffies'
export const EXTRA = 'extra'
export const PHONE_PATTERN = '^(\\+)?(\\d+)$'
export const DEBOUNCE_TIME = 500
export const SELECT_MINIMUM_LIMIT = 100
export const SELECT_MAXIMUM_LIMIT = 300
export const AUTO_COMPLETE = 'auto-complete'
export const POSITIVE_NUMBERS = /^(?!0\d)\d*$/
export const REGEX_SKU = /^$|^[a-zA-Z0-9_-]{0,15}$/
export const SKU_LIKE = 'sku_like'
export const FILTER = 'filter'
export const UNDERSCORE_LIKE = '_like'
export const SUPPLIER_CODE = 'supplier_code'
export const ITEM_LINGUISTIC_ID = 'item_linguistic_id'
export const EN = 'en'
export const FR = 'fr'
export const DESCRIPTION_EN = 'description_en'
export const DESCRIPTION_FR = 'description_fr'
export const SELECT_3_DOTS = 'select_3_dots'
export const LOGOUT_IDLE_TIME_MINUTES = 60
export const REGEX_URL =
  /^(?!www\.)^(-?)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i

export const ORDER_STATUS = {
  RECEIVED: 1,
  RELEASED: 2,
  BATCHED: 3,
  PICKING: 4,
  WAIT_PACKING: 5,
  PACKING: 6,
  PACKED: 7,
  PARTIAL: 8,
  WAIT_PICKUP: 9,
  PARTIAL_SHIPPED: 10,
  SHIPPED: 11,
  BACKORDER: 12,
  ON_HOLD: 13,
  CANCELLED: 14,
}
export const PURCHASE_ORDER_STATUS = {
  OPEN: 1,
  CLOSED: 2,
  PARTIAL: 3,
  CANCELLED: 4,
}
export const LOCATION_CATEGORIES = {
  BIN: 1,
  SHELF: 2,
  SKID: 3,
  PALETTE: 4,
  TOTE: 5,
  CAGE: 6,
  PACKING_TABLE: 7,
}
export const INVENTORY_TYPE = {
  QUICK_PICK: 1,
  LOADING_LOCATION: 2,
}
export const APPOINTMENT_STATUS = {
  OPEN: 1,
  DELIVERED: 2,
  UNSCHEDULED: 3,
  REFUSED: 4,
  LATE: 5,
  CANCELLED: 6,
  MISSED: 7,
  RECEIVING: 8,
  COMPLETED: 9,
}
export const ITEM_TYPES = {
  ITEM: 1,
  KIT: 2,
  EMBALL: 3,
  SUPPLY: 4,
}
export const JOB_TYPES = {
  PICK: 1,
  PACK: 2,
  REPLENISHMENT: 3,
}
export const CARRIERS = {
  CANADA_POST: 1,
  UPS: 2,
  CANPAR: 3,
  MTL: 4,
  FEDEX: 5,
  PUROLATOR: 6,
  BOX_KNIGHT: 7,
  NATIONEX: 8,
  XPEDIGO: 9,
  OTHER: 10,
}
export const STATUS_COLOR = {
  1: 'success',
  2: 'danger',
  3: 'info',
}
export const SUPPLY_COLORS = {
  CLIENT_3DM: 'green',
  CLIENT: 'blue',
}
export const LANGUAGES = {
  ENGLISH: 1,
  FRENCH: 2,
}
export const FILE_UPLOAD_LENGTH = 100
export const FILE_UPLOAD_SIZE = 6291456
export const DEBOUNCE_INPUT_DELAY = 500
export const FILTER_ITEM_SELECT = {
  SKU: 'SKU',
  // UPC: 'UPC',
  // SUPPLIER: 'Supplier Code',
}
export const JOB_STATUS = {
  OPEN: 1,
  IN_PROGRESS: 2,
  COMPLETED: 3,
}

export const RETURN_STATUS = {
  OPEN: 1,
  CLOSED: 2,
  PENDING_CLIENT: 3,
  PENDING_3DM: 4,
}
export const RETURN_ITEM_STATUS = {
  PENDING_3DM: 1,
  CLIENT_APPROVED: 2,
  OPEN: 3,
  CLOSED: 4,
  PENDING_CLIENT: 5,
}
export const RETURN_ITEM_ACTION = {
  BACK_TO_STOCK: 2,
}
export const PICKING_STEPS = {
  STEP_TICKET: 'ticket',
  STEP_LOCATION: 'location',
  STEP_ITEM: 'item',
  STEP_TOTE: 'tote',
  STEP_EMPTY: '',
}

export const ITEM_STEPS = {
  GENERAL_INFO: 1,
  PRODUCT_DETAILS: 2,
  MANUFACTURE_DETAILS: 3,
  UOM: 4,
  ATTRIBUTES: 5,
  KIT_COMPONENT: 6,
  LOG: 7,
}
export const PERMISSIONS = {
  CREATE: 'create',
  MODIFY: 'modify',
  DELETE: 'delete',
  LIST: 'list',
  PRINT: 'print',
  ITEM: 'item',
  ITEM_BARCODE: 'itembarcode',
}
export const FILE_TYPES = {
  IMAGE_PNG: 'image/png',
  IMAGE_JPEG: 'image/jpeg',
  IMAGE_JPG: 'image/jpg',
}
export const LIST_FLAG = {
  ALL: 'all',
}
export const COMMON_STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
}
export const COMMON_STATUS_NAME = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
}
export const ROUTES = {
  ITEM_LIST: '/items/items',
  ITEM_EDIT: '/items/items/edit',
  ITEM_CREATE: '/items/items/create',
  ITEM_IMPORT: '/items/items/import',
  APPOINTMENT_LIST: '/appointments',
  RECEIVING_LIST: '/receiving/',
  RECEIVING_LIST_WITH_APPOINTMENT: '/receiving/appointment/',
  RECEIVING_EDIT: '/receiving/receiving/edit',
  RECEIVING_CREATE: '/receiving/receiving/create',
  RETURN_LIST: '/return',
  RETURN_CREATE: '/return/create',
  RETURN_EDIT: '/return/edit',
  RETURN_VIEW: '/return/view',
  BILL_LIST: '/system/billings/bill',
  BILL_CREATE: '/system/billings/bill/create',
  BILL_EDIT: '/system/billings/bill/edit',
  BILL_DETAIL: '/system/billings/billing-details',
  WAREHOUSE_LIST: '/warehouses',
  WAREHOUSE_EDIT: '/warehouses/edit',
  WAREHOUSE_CREATE: '/warehouses/create',
  CARRIER_LIST: '/system/carriers/carriers',
  CARRIER_EDIT: '/system/carriers/edit',
  CARRIER_CREATE: '/system/carriers/create',
  PLATFORM_LIST: '/system/platforms/platforms',
}
export const RECEIVING_ITEM_STATUS = {
  OPEN: 1,
  CLOSED: 2,
}
export const RECEIVING_STATUS = {
  IN_PROGRESS: 1,
  ON_HOLD: 2,
  PENDING: 3,
  VERIFICATION: 4,
  FINALIZED: 5,
}
export const SHAPE = {
  STATUS: 'rounded-pill',
  CREATE: 'pill',
  EDIT: 'pill',
  DELETE: 'pill',
  SUBMIT: 'pill',
  BACK: 'pill',
  SEARCH: 'pill',
  RESET: 'pill',
  FILTER: 'pill',
  UPLOAD: 'pill',
  DETAIL: 'rounded-pill',
}
export const BTN_COLOR = {
  CREATE: 'primary',
  EDIT: 'secondary',
  DELETE: 'danger',
  SUBMIT: 'primary',
  BACK: 'secondary',
  SEARCH: 'primary',
  RESET: 'dark',
  FILTER: 'light',
  UPLOAD: 'primary',
  INVOICE: 'warning',
  EXPORT: 'success',
  IMPORT: 'info',
  ACTION: 'light',
  LINK: 'link',
  PRINT: 'info',
}
export const RECEIVED_BY = {
  CONTAINER: 'container',
  TRUCK: 'truck',
}
export const DELIVERY_STATUS = {
  NON_SCHEDULED: 1,
  EARLY: 2,
  LATE: 3,
  ON_TIME: 4,
}
export const UOM_TYPE = {
  EACH: 1,
  CARTON: 2,
  PALLET: 3,
}
export const REPLENISHMENT_STEPS = {
  STEP_TICKET: 'ticket',
  STEP_LOCATION: 'location',
  STEP_ITEM: 'item',
  STEP_TOTE: 'tote',
  STEP_QP_LOCATION: 'qp_location',
  STEP_EMPTY: EMPTY_STRING,
}

export const PICK_PACK_STEPS = {
  STEP_TICKET: 'ticket',
  STEP_ORDERS: 'order',
  STEP_ITEM: 'item',
  STEP_LOCATION: 'location',
  STEP_SUPPLY_ZONE: 'supply_zone',
  STEP_MANUAL_SUPPLY_CATEGORY: 'manual_supply_category',
  STEP_MANUAL_SUPPLY_LIST: 'manual_supply_list',
  STEP_SCAN_SUPPLY: 'scan_supply',
  STEP_EMPTY: EMPTY_STRING,
}

export const LOCATION_CONDITIONS = {
  AVAILABLE: 0,
  ORDER: 1,
  REPLENISHMENT: 2,
}

export const INVENTORY_TRANSFER_TYPE = {
  NORMAL_TRANSFER: NUMBER_ONE,
  LOADING_LOCATION_TO_QUICK_ZONE_TRANSFER: NUMBER_TWO,
  REPLENISHMENT_TOTE_TRANSFER: NUMBER_THREE,
  TOTE_QUICK_ZONE_TRANSFER: NUMBER_FOUR,
}

export const ORDER_PACKAGE_STATUS = {
  OPEN: 1,
  UNDER_PACKING: 2,
  PENDING: 3,
  PACKED: 4,
  SHIPPED: 5,
}

export const QUANTITY_TYPES = {
  AVAILABLE: 1,
  RESERVED: 2,
  RECEIVING: 3,
  ALLOCATED: 4,
  DAMAGED: 5,
  QUARANTINED: 6,
  RETURNED: 7,
  EXPIRED: 8,
  ON_HOLD: 9,
  OTHERS: 10,
}

export const QUANTITY_TYPES_COLOR = {
  1: 'success', // AVAILABLE
  2: 'warning', // RESERVED
  3: 'info', // RECEIVING
  4: 'primary', // ALLOCATED
  5: 'danger', // DAMAGED
  6: 'dark', // QUARANTINED
  7: 'warning', // RETURNED
  8: 'dark', // EXPIRED
  9: 'danger', // ON_HOLD
  10: 'dark', // OTHERS
}

export const MODIFICATION_TYPES = {
  ADD: 1,
  MINUS: 2,
  REPLACE: 3,
  QUARANTINE: 4,
  DAMAGED: 5,
  CLIENT_REQUEST: 6,
  OTHER: 7,
}

export const LOG_STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
}

export const PICKING_METHODS = {
  BATCH_ORDER_PICK: NUMBER_ONE,
  SINGLE_ORDER_PICK: NUMBER_TWO,
  BATCH_PICK: NUMBER_THREE,
  PICK_PACK: NUMBER_FOUR,
}

export const ACTION_TYPES = {
  ADD: ADD,
  REMOVE: REMOVE,
}

export const SUPPLY_CATEGORIES = {
  BOX: 1,
  JIFFY: 2,
  EXTRA: 3,
  VINYLS: 4,
  CONFORMERS: 5,
}

export const DATE_FORMAT = 'YYYY/MM/DD'

export const CLIENT_STEPS = {
  COMPANY_INFORMATION: 1,
  CLIENT_ATTRIBUTES: 2,
  WAREHOUSE_PRIORITIES: 3,
  CARRIERS: 4,
  CONTACTS: 5,
  LINE_CHARGE: 6,
  SCALES: 7,
  LOG: 8,
  INTEGRATIONS: 9,
  LEGACY_SYNC: 10,
}

export const CLIENT_INTEGRATION_STEPS = {
  SETUP: 1,
  SETTINGS: 2,
}

export const SCHEDULE_TYPE = {
  MINUTES: 'Minutes',
  HOURLY: 'Hourly',
  DAILY: 'Daily',
  CUSTOM: 'Custom',
}

export const DAYS = {
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
  SUNDAY: 'Sunday',
}

export const FULFILLMENT_STATUSES = {
  SHIPPED: 'Shipped',
  PARTIAL: 'Partial',
  UNSHIPPED: 'Unshipped',
  UNFULFILLED: 'Unfulfilled',
  ANY: 'Any',
}

export const FINANCIAL_STATUSES = {
  PENDING: 'Pending',
  AUTHORIZED: 'Authorized',
  ANY: 'Any',
  EXPIRED: 'Expired',
  PAID: 'Paid',
  REFUNDED: 'Refunded',
  PARTIALLY_REFUNDED: 'Partially Refunded',
  PARTIALLY_PAID: 'Partially Paid',
  VOIDED: 'Voided',
  UNPAID: 'Unpaid',
}

export const SEARCH_LIMIT = [15, 50, 100, 300]

export const SEARCH_METHOD = ['desc', 'asc']

export const MEMBERSHIP_TYPES = {
  GENERAL: 1,
  PLUS: 2,
}
