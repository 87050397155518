import http from '../utils/http-common'

const getConfigWarehouses = (param) => {
  return http.get('/config/warehouses', { params: param })
}
const getConfigWarehouse = (id) => {
  return http.get(`/config/warehouses/${id}`)
}
const getAll = (param) => {
  return http.get('/warehouses', { params: param })
}
const get = (id) => {
  return http.get(`/warehouses/${id}`)
}
const getWithParams = (id, params) => {
  return http.get(`/warehouses/${id}?${params}`)
}
const create = (data) => {
  return http.post('/warehouses', data)
}
const update = (id, data) => {
  return http.put(`/warehouses/${id}`, data)
}
const remove = (id) => {
  return http.delete(`/warehouses/${id}`)
}
const removeAll = () => {
  return http.delete(`/warehouses`)
}
const findByTitle = (title) => {
  return http.get(`/warehouses?title=${title}`)
}
const getDoor = (id) => {
  return http.get(`/warehouse-doors/${id}`)
}
const createDoor = (wh_id, data) => {
  return http.post(`/warehouse-doors/create/${wh_id}`, data)
}
const updateDoor = (id, data) => {
  return http.put(`/warehouse-doors/update/${id}`, data)
}
const removeDoor = (id) => {
  return http.delete(`/warehouse-doors/${id}`)
}
const findByName = (name) => {
  return http.get(`/warehouses?list=auto-complete&title=${name}`)
}
const addLineCharge = (data) => {
  return http.post(`/warehouse-line-charges`, data)
}
const updateLineCharge = (id, data) => {
  return http.put(`/warehouse-line-charges/${id}`, data)
}
const deleteLineCharge = (id) => {
  return http.delete(`/warehouse-line-charges/${id}`)
}

const WarehouseDataService = {
  getConfigWarehouses,
  getConfigWarehouse,
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle,
  getDoor,
  createDoor,
  updateDoor,
  removeDoor,
  findByName,
  getWithParams,
  addLineCharge,
  updateLineCharge,
  deleteLineCharge,
}
export default WarehouseDataService
