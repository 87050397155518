import http from '../utils/http-common'

const getAll = (param) => {
  return http.get('/picking-methods', { params: param })
}
const get = (id) => {
  return http.get(`/picking-methods/${id}`)
}

const getPickingJob = (param) => {
  return http.get('/order-item-picking-job', { params: param })
}

const beginPickingJob = (data) => {
  return http.post('/begin-order-picking-job', data)
}

const transferPikingItem = (data) => {
  return http.post('/transfer-order-item-picking-job', data)
}

const PickingDataService = {
  getAll,
  get,
  getPickingJob,
  beginPickingJob,
  transferPikingItem,
}
export default PickingDataService
