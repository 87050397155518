import React from 'react'
import { CButton, CCol, CContainer, CRow } from '@coreui/react-pro'
import { BTN_COLOR, COMMON } from 'src/constants/Constants'
import { useNavigate } from 'react-router-dom'
import logo3DM from 'src/assets/brand/3dm-o.png'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { logout } from 'src/actions'

const Page500 = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation(COMMON)
  const logoutUser = () => {
    dispatch(logout())
    setTimeout(() => {
      navigate('/login', { replace: true })
    }, 500)
  }
  return (
    <div className="min-vh-100">
      <div className="bg-light d-flex flex-row align-items-center min-vh-100">
        <CContainer>
          <CRow className="justify-content-top page-not-found-logo-container">
            <img src={logo3DM} alt={'logo'} className="page-not-found-logo"></img>
          </CRow>
          <CRow className="justify-content-center">
            <CCol md={4}>
              <div className="clearfix">
                <h1 className="display-3 me-4">{t('code_500')}</h1>
                <h4 className="pt-3">{t('msg_500')}</h4>
                <p className="text-medium-emphasis float-start">{t('desc_500')}</p>
              </div>
            </CCol>
          </CRow>
          <CRow className="justify-content-center go-to-dashboard-container">
            <CCol sm={4}>
              <div className="clearfix">
                <p className="display-3 me-4">
                  <CButton
                    color={BTN_COLOR.CREATE}
                    type={'button'}
                    onClick={() => {
                      setTimeout(() => {
                        navigate('/dashboard', { replace: true })
                      }, 200)
                    }}
                  >
                    {t('go_to_dashboard')}
                  </CButton>
                  <CButton
                    color={BTN_COLOR.CREATE}
                    className="mx-3"
                    type={'button'}
                    onClick={logoutUser}
                  >
                    {t('re_login')}
                  </CButton>
                </p>
              </div>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </div>
  )
}

export default Page500
