import http from '../utils/http-common'
import { exportData } from '../utils/http-common'

const getAll = (param) => {
  return http.get(`/orders`, { params: param })
}

const getStatus = () => {
  return http.get(`/order-statuses`)
}

const getOrderItemStatus = () => {
  return http.get(`/order-item-statuses`)
}

const create = (data) => {
  return http.post('/orders', data)
}

const update = (id, data) => {
  return http.put(`/orders/${id}`, data)
}

const get = (id) => {
  return http.get(`/orders/${id}`)
}

const cancelOrder = (id) => {
  return http.post(`/orders/cancel/${id}`)
}

const changeStatus = (orders, order_status_id) => {
  return http.post(`/orders/change-status`, { orders, order_status_id })
}

const cancelOrders = (data) => {
  return http.post(`/order-cancel`, data)
}

const addComment = (data) => {
  return http.post(`/order-comments`, data)
}

const deleteComment = (id) => {
  return http.delete(`/order-comments/${id}`)
}

const batchOrders = (data) => {
  return http.post(`/batches`, data)
}

const unbatchOrders = (data) => {
  return http.post(`/unbatch`, data)
}

const exportOrders = () => {
  return exportData.get(`/export/orders`)
}
const getCSVTemplate = () => {
  return http.get(`/import/order-structure`)
}
const importCSV = (data) => {
  return http.post(`/import/orders`, data)
}

const getOrderPackages = (param) => {
  return http.get(`/order-package`, { params: param })
}

const getOrderPackageStatus = () => {
  return http.get(`/order-package-statuses`)
}
const emailExportExcel = (param) => {
  return http.get(`/export/orders`, { params: param })
}

const OrdersDataService = {
  getAll,
  getStatus,
  getOrderItemStatus,
  create,
  update,
  get,
  cancelOrder,
  changeStatus,
  addComment,
  deleteComment,
  batchOrders,
  unbatchOrders,
  exportOrders,
  getCSVTemplate,
  importCSV,
  getOrderPackages,
  getOrderPackageStatus,
  cancelOrders,
  emailExportExcel,
}
export default OrdersDataService
