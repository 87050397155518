import http from '../utils/http-common'

const getAll = (param) => {
  return http.get(`/tickets`, { params: param })
}

const get = (id) => {
  return http.get(`/tickets/${id}`)
}

const getJobsStatus = () => {
  return http.get(`/tickets-statuses`, {})
}

const getJobsType = () => {
  return http.get(`/tickets-types`, {})
}

const updateJob = (id, data) => {
  return http.put(`/tickets/${id}`, data)
}

const getReplishments = (id) => {
  return http.get(`/replenishment/${id}`)
}

const getCSVTemplate = () => {
  return http.get(`/import/job-structure`)
}

const importCSV = (data) => {
  return http.post(`/import/jobs`, data)
}

const getPickingDetails = (data) => {
  return http.get(`/order-item-pickings?order_id=${data}`)
}

const emailExportExcel = (param) => {
  return http.get(`/export/jobs`, { params: param })
}

const JobsDataService = {
  getAll,
  get,
  getJobsStatus,
  getJobsType,
  updateJob,
  getReplishments,
  getCSVTemplate,
  importCSV,
  getPickingDetails,
  emailExportExcel,
}

export default JobsDataService
