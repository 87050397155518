import axios from 'axios'
import AppointmentDataService from 'src/services/AppointmentDataService'
import BillingCategoryDataService from 'src/services/BillingCategoryDataService'
import BillingUOMDataService from 'src/services/BillingUOMDataService'
import CarrierDataService from 'src/services/CarrierDataService'
import DepartmentDataService from 'src/services/DepartmentDataService'
import ItemConditionsDataService from 'src/services/ItemConditionsDataService'
import ItemStatusesDataService from 'src/services/ItemStatusesDataService'
import ItemTypesDataService from 'src/services/ItemTypesDataService'
import OrdersDataService from 'src/services/OrdersDataService'
import OrderTypesService from 'src/services/OrderTypesService'
import PickingDataService from 'src/services/PickingDataService'
import ProvinceDataService from 'src/services/ProvinceDataService'
import QuantityTypesDataService from 'src/services/QuantityTypesDataService'
import ReceivingDataService from 'src/services/ReceivingDataService'
import ReturnStatusService from 'src/services/ReturnStatusService'
import SalutationDataService from 'src/services/SalutationDataService'
import SettingDataService from 'src/services/SettingDataService'
import ShippingMethodsDataService from 'src/services/ShippingMethodsDataService'
import SupplierDataService from 'src/services/SupplierDataService'
import SupplyTypesDataService from 'src/services/SupplyTypesDataService'
import UomTypesDataService from 'src/services/UomTypesDataService'
import CountryDataService from '../services/CountryDataService'
import CurrenciesDataService from '../services/CurrenciesDataService'
import DeliveryTypeDataService from '../services/DeliveryTypeDataService'
import DoorTypeDataService from '../services/DoorTypeDataService'
import HolidaysDataService from '../services/HolidaysDataService'
import ItemReasonsDataService from '../services/ItemReasonsDataService'
import JobsDataService from '../services/JobsDataService'
import LanguageDataService from '../services/LanguageDataService'
import LengthClassDataService from '../services/LengthClassDataService'
import LocationCategoryDataService from '../services/LocationCategoryDataService'
import LocationTypeDataService from '../services/LocationTypeDataService'
import ModificationTypeDataService from '../services/ModificationTypeDataService'
import PurchaseOrderDataService from '../services/PurchaseOrderDataService'
import TaxClassesDataService from '../services/TaxClassesDataService'
import WarehouseDataService from '../services/WarehouseDataService'
import WeightClassDataService from '../services/WeightClassDataService'
import cookiesUtils from '../utils/cookie-utils'
import {
  APPOINTMENT_STATUS_DATA,
  APPOINTMENT_TASKS_DATA,
  BILLING_CATEGORIES_DATA,
  BILLING_UOM_DATA,
  CARRIERS_DATA,
  CHANGE_LANGUAGE_DATA,
  COUNTRIES_DATA,
  CURRENCY_DATA,
  DELIVERY_STATUS_DATA,
  DELIVERY_TYPE_DATA,
  DEPARTMENTS_DATA,
  DOOR_TYPE_DATA,
  HOLIDAY_DATA,
  ITEM_CONDITIONS_DATA,
  ITEM_REASON_DATA,
  ITEM_STATUS_DATA,
  ITEM_TYPES_DATA,
  JOBS_STATUS_DATA,
  JOBS_TYPE_DATA,
  LANGUAGE_DATA,
  LENGTH_CLASS_DATA,
  LOCATION_CATEGORY_DATA,
  LOCATION_TYPE_DATA,
  LOGIN_BEGIN,
  LOGIN_ERROR,
  LOGOUT_BEGIN,
  MODIFICATION_TYPE_DATA,
  NO_USER,
  ORDER_ITEM_STATUS_DATA,
  ORDER_PACKAGE_STATUS_DATA,
  ORDER_STATUS_DATA,
  ORDER_TYPES_DATA,
  PICKING_METHODS_DATA,
  PO_CONDITIONS_DATA,
  PO_STATUSES_DATA,
  PROVINCES_DATA,
  QUANTITY_TYPES_DATA,
  RECEIVING_ITEM_REASON_DATA,
  RECEIVING_ITEM_STATUS_DATA,
  RECEIVING_PO_REASON_DATA,
  RECEIVING_PO_STATUS_DATA,
  RECEIVING_STATUS_DATA,
  RETURN_ACTIONS_DATA,
  RETURN_ITEM_ACTIONS_DATA,
  RETURN_ITEM_CONDITIONS_DATA,
  RETURN_ITEM_REASONS_DATA,
  RETURN_ITEM_STATUSES_DATA,
  RETURN_REASON_DATA,
  RETURN_STATUSES_DATA,
  SALUTATION_DATA,
  SELECTED_INVENTORY,
  SERVICES_DATA,
  SETTING_DATA,
  SHIPPING_METHODS_DATA,
  SUPPLIERS_DATA,
  SUPPLY_TYPES_DATA,
  TAX_DATA,
  UOM_TYPE_DATA,
  USER_DATA,
  WAREHOUSE_DATA,
  WEIGHT_CLASS_DATA,
} from './actionType'

export function currentUser() {
  return (dispatch) => {
    axios
      .get('/api/user')
      .then((response) => response.data)
      .then((data) => {
        if (data.user === 0) {
          return dispatch(noUser())
        }
        return dispatch(userLoginData(data))
      })
  }
}

export const userLoginData = (data) => ({
  type: USER_DATA,
  payload: data,
})

export const languagesData = (data) => ({
  type: LANGUAGE_DATA,
  payload: data,
})

export const userLanguageData = (data) => ({
  type: CHANGE_LANGUAGE_DATA,
  payload: data,
})

export const noUser = () => ({
  type: NO_USER,
})

export function login(email, password) {}

export const startLogin = () => ({
  type: LOGIN_BEGIN,
})

export const loginError = (msg) => ({
  type: LOGIN_ERROR,
  payload: msg,
})

export function logout() {
  return (dispatch) => {
    dispatch(logoutBegin())
    cookiesUtils.logout()
  }
}

export const logoutBegin = () => ({
  type: LOGOUT_BEGIN,
})

export const updateSelectedInventory = (data) => ({
  type: SELECTED_INVENTORY,
  payload: data,
})

export const fetchCountries = (c) => {
  return (dispatch) => {
    if (c && c.length === 0) {
      CountryDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: COUNTRIES_DATA, payload: res.data.data })
      })
    }
    return c
  }
}

export const fetchProvinces = (p) => {
  return (dispatch) => {
    if (p && p.length === 0) {
      ProvinceDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: PROVINCES_DATA, payload: res.data.data })
      })
    }
    return p
  }
}

export const fetchCarriers = (ca) => {
  return (dispatch) => {
    if (ca && ca.length === 0) {
      CarrierDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: CARRIERS_DATA, payload: res.data.data })
      })
    }
    return ca
  }
}

export const fetchServices = (ca) => {
  return (dispatch) => {
    if (ca && ca.length === 0) {
      CarrierDataService.getServices().then((res) => {
        dispatch({ type: SERVICES_DATA, payload: res.data.data })
      })
    }
    return ca
  }
}

export const fetchOrderTypes = (ca) => {
  return (dispatch) => {
    if (ca && ca.length === 0) {
      OrderTypesService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: ORDER_TYPES_DATA, payload: res.data.data })
      })
    }
    return ca
  }
}

export const fetchAppointmentTasks = (a) => {
  return (dispatch) => {
    if (a && a.length === 0) {
      AppointmentDataService.getTasks().then((res) => {
        dispatch({ type: APPOINTMENT_TASKS_DATA, payload: res.data.data })
      })
    }
    return a
  }
}

export const fetchAppointmentStatus = (a) => {
  return (dispatch) => {
    if (a && a.length === 0) {
      AppointmentDataService.getStatuses().then((res) => {
        dispatch({ type: APPOINTMENT_STATUS_DATA, payload: res.data.data })
      })
    }
    return a
  }
}

export const fetchSuppliers = (S) => {
  return (dispatch) => {
    if (S && S.length === 0) {
      SupplierDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: SUPPLIERS_DATA, payload: res.data.data })
      })
    }
    return S
  }
}

export const fetchLanguages = (data) => {
  return (dispatch) => {
    if (data && data.length === 0) {
      LanguageDataService.getAll({ list: 'all' }).then((res) => {
        if (res.data?.success) {
          dispatch({ type: LANGUAGE_DATA, payload: res.data.data })
        }
      })
    }
    return data
  }
}

export const fetchCurrencies = (S) => {
  return (dispatch) => {
    if (S && S.length === 0) {
      CurrenciesDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: CURRENCY_DATA, payload: res.data.data })
      })
    }
    return S
  }
}

export const fetchModificationTypes = (S) => {
  return (dispatch) => {
    if (S && S.length === 0) {
      ModificationTypeDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: MODIFICATION_TYPE_DATA, payload: res.data.data })
      })
    }
    return S
  }
}

export const fetchItemReasons = (S) => {
  return (dispatch) => {
    if (S && S.length === 0) {
      ItemReasonsDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: ITEM_REASON_DATA, payload: res.data.data })
      })
    }
    return S
  }
}

export const fetchWarehouses = (S) => {
  return (dispatch) => {
    if (S && S.length === 0) {
      WarehouseDataService.getAll({ list: 'all', limit: 500 }).then((res) => {
        dispatch({ type: WAREHOUSE_DATA, payload: res.data.data })
      })
    }
    return S
  }
}
export const fetchDeliveryTypes = (S) => {
  return (dispatch) => {
    if (S && S.length === 0) {
      DeliveryTypeDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: DELIVERY_TYPE_DATA, payload: res.data.data })
      })
    }
    return S
  }
}
export const fetchPurchaseOrderConditions = (S) => {
  return (dispatch) => {
    if (S && S.length === 0) {
      PurchaseOrderDataService.getConditions().then((res) => {
        dispatch({ type: PO_CONDITIONS_DATA, payload: res.data.data })
      })
    }
    return S
  }
}

export const fetchPurchaseOrderStatuses = (S) => {
  return (dispatch) => {
    if (S && S.length === 0) {
      PurchaseOrderDataService.getStatuses().then((res) => {
        dispatch({ type: PO_STATUSES_DATA, payload: res.data.data })
      })
    }
    return S
  }
}

export const fetchShippingMethods = (S) => {
  return (dispatch) => {
    if (S && S.length === 0) {
      ShippingMethodsDataService.getAll().then((res) => {
        dispatch({ type: SHIPPING_METHODS_DATA, payload: res.data.data })
      })
    }
    return S
  }
}

export const fetchOrderStatus = (data) => {
  return (dispatch) => {
    if (data && data.length === 0) {
      OrdersDataService.getStatus().then((res) => {
        dispatch({ type: ORDER_STATUS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchOrderItemStatus = (data) => {
  return (dispatch) => {
    if (!data || (data && data?.length === 0)) {
      OrdersDataService.getOrderItemStatus().then((res) => {
        dispatch({ type: ORDER_ITEM_STATUS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchOrderPackageStatus = (data) => {
  return (dispatch) => {
    if (!data || (data && data?.length === 0)) {
      OrdersDataService.getOrderPackageStatus().then((res) => {
        dispatch({ type: ORDER_PACKAGE_STATUS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchReceivingStatus = (S) => {
  return (dispatch) => {
    if (S && S.length === 0) {
      ReceivingDataService.getStatuses().then((res) => {
        dispatch({ type: RECEIVING_STATUS_DATA, payload: res.data.data })
      })
    }
    return S
  }
}

export const fetchDeliveryStatus = (S) => {
  return (dispatch) => {
    if (S && S.length === 0) {
      ReceivingDataService.getDeliveryStatuses().then((res) => {
        dispatch({ type: DELIVERY_STATUS_DATA, payload: res.data.data })
      })
    }
    return S
  }
}

export const fetchUomTypes = (uom) => {
  return (dispatch) => {
    if (uom && uom.length === 0) {
      UomTypesDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: UOM_TYPE_DATA, payload: res.data.data })
      })
    }
    return uom
  }
}

export const fetchSalutations = (data) => {
  return (dispatch) => {
    if (data && data.length === 0) {
      SalutationDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: SALUTATION_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchLocationTypes = (data) => {
  return (dispatch) => {
    if (data && data.length === 0) {
      LocationTypeDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: LOCATION_TYPE_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchLocationCategories = (data) => {
  return (dispatch) => {
    if (data && data.length === 0) {
      LocationCategoryDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: LOCATION_CATEGORY_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchLengthClass = (data) => {
  return (dispatch) => {
    if (data && data.length === 0) {
      LengthClassDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: LENGTH_CLASS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}
export const fetchDepartments = (data) => {
  return (dispatch) => {
    if (data && data.length === 0) {
      DepartmentDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: DEPARTMENTS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}
export const fetchWeightClass = (data) => {
  return (dispatch) => {
    if (data && data.length === 0) {
      WeightClassDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: WEIGHT_CLASS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchSupplyTypes = (data) => {
  return (dispatch) => {
    if (data && data.length === 0) {
      SupplyTypesDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: SUPPLY_TYPES_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchItemTypes = (data) => {
  return (dispatch) => {
    if (data && data.length === 0) {
      ItemTypesDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: ITEM_TYPES_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchItemStatus = (data) => {
  return (dispatch) => {
    if (data && data.length === 0) {
      ItemStatusesDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: ITEM_STATUS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchReturnReason = (data) => {
  return (dispatch) => {
    if (!data || (data && data?.length === 0)) {
      ReturnStatusService.getReturnReason().then((res) => {
        dispatch({ type: RETURN_REASON_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchReturnStatuses = (data) => {
  return (dispatch) => {
    if (!data || (data && data?.length === 0)) {
      ReturnStatusService.getReturnStatuses().then((res) => {
        dispatch({ type: RETURN_STATUSES_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchReturnActions = (data) => {
  return (dispatch) => {
    if (!data || (data && data?.length === 0)) {
      ReturnStatusService.getReturnActions().then((res) => {
        dispatch({ type: RETURN_ACTIONS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchReturnItemReasons = (data) => {
  return (dispatch) => {
    if (!data || (data && data?.length === 0)) {
      ReturnStatusService.getReturnItemReasons().then((res) => {
        dispatch({ type: RETURN_ITEM_REASONS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchReturnItemConditions = (data) => {
  return (dispatch) => {
    if (!data || (data && data?.length === 0)) {
      ReturnStatusService.getReturnItemConditions().then((res) => {
        dispatch({ type: RETURN_ITEM_CONDITIONS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchReturnItemActions = (data) => {
  return (dispatch) => {
    if (!data || (data && data?.length === 0)) {
      ReturnStatusService.getReturnItemActions().then((res) => {
        dispatch({ type: RETURN_ITEM_ACTIONS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchReturnItemStatuses = (data) => {
  return (dispatch) => {
    if (!data || (data && data?.length === 0)) {
      ReturnStatusService.getReturnItemStatuses().then((res) => {
        dispatch({ type: RETURN_ITEM_STATUSES_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchJobsType = (data) => {
  return (dispatch) => {
    if (data && data.length === 0) {
      JobsDataService.getJobsType().then((res) => {
        dispatch({ type: JOBS_TYPE_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchJobsStatus = (data) => {
  return (dispatch) => {
    if (data && data.length === 0) {
      JobsDataService.getJobsStatus().then((res) => {
        dispatch({ type: JOBS_STATUS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchSettings = (data) => {
  return (dispatch) => {
    if (data && data.length === 0) {
      SettingDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: SETTING_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchBillingCategories = (data) => {
  return (dispatch) => {
    if (data && data.length === 0) {
      BillingCategoryDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: BILLING_CATEGORIES_DATA, payload: res.data.data })
      })
    }
    return data
  }
}

export const fetchBillingUOMTypes = (data) => {
  return (dispatch) => {
    if (data && data.length === 0) {
      BillingUOMDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: BILLING_UOM_DATA, payload: res.data.data })
      })
    }
    return data
  }
}
export const fetchHolidays = (S) => {
  return (dispatch) => {
    if (S && S.length === 0) {
      HolidaysDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: HOLIDAY_DATA, payload: res.data.data })
      })
    }
    return S
  }
}
export const fetchDoorTypes = (S) => {
  return (dispatch) => {
    if (S && S.length === 0) {
      DoorTypeDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: DOOR_TYPE_DATA, payload: res.data.data })
      })
    }
    return S
  }
}
export const fetchTaxes = (S) => {
  return (dispatch) => {
    if (S && S.length === 0) {
      TaxClassesDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: TAX_DATA, payload: res.data.data })
      })
    }
    return S
  }
}
export const fetchAppointmentStatuses = (S) => {
  return (dispatch) => {
    if (S && S.length === 0) {
      AppointmentDataService.getStatuses({ list: 'all' }).then((res) => {
        dispatch({ type: APPOINTMENT_STATUS_DATA, payload: res.data.data })
      })
    }
    return S
  }
}
export const fetchReceivingItemReasons = (S) => {
  return (dispatch) => {
    if (S && S.length === 0) {
      ReceivingDataService.getReceivingItemReasons().then((res) => {
        dispatch({ type: RECEIVING_ITEM_REASON_DATA, payload: res.data.data })
      })
    }
    return S
  }
}
export const fetchReceivingItemStatuses = (S) => {
  return (dispatch) => {
    if (S && S.length === 0) {
      ReceivingDataService.getReceivingItemStatuses().then((res) => {
        dispatch({ type: RECEIVING_ITEM_STATUS_DATA, payload: res.data.data })
      })
    }
    return S
  }
}
export const fetchReceivingPOStatuses = (S) => {
  return (dispatch) => {
    if (S && S.length === 0) {
      ReceivingDataService.getReceivingPOStatuses().then((res) => {
        dispatch({ type: RECEIVING_PO_STATUS_DATA, payload: res.data.data })
      })
    }
    return S
  }
}
export const fetchReceivingPOReasons = (S) => {
  return (dispatch) => {
    if (S && S.length === 0) {
      ReceivingDataService.getReceivingPOReasons().then((res) => {
        dispatch({ type: RECEIVING_PO_REASON_DATA, payload: res.data.data })
      })
    }
    return S
  }
}
export const fetchQuantityTypes = (data) => {
  return (dispatch) => {
    if (data && data.length === 0) {
      QuantityTypesDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: QUANTITY_TYPES_DATA, payload: res.data.data })
      })
    }
    return data
  }
}
export const fetchItemConditions = (data) => {
  return (dispatch) => {
    if (data && data.length === 0) {
      ItemConditionsDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: ITEM_CONDITIONS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}
export const fetchPickingMethods = (data) => {
  return (dispatch) => {
    if (data && data.length === 0) {
      PickingDataService.getAll({ list: 'all' }).then((res) => {
        dispatch({ type: PICKING_METHODS_DATA, payload: res.data.data })
      })
    }
    return data
  }
}
