import http from '../utils/http-common'

const getAll = (param) => {
  return http.get(`/receivings`, { params: param })
}
const get = (id) => {
  return http.get(`/receivings/${id}`)
}
const getStatuses = () => {
  return http.get(`/receiving-statuses`)
}
const getDeliveryStatuses = () => {
  return http.get(`/delivery-statuses`)
}
const getReceivingItemReasons = () => {
  return http.get(`/receiving-item-reasons`)
}
const getReceivingItemStatuses = () => {
  return http.get(`/receiving-item-statuses`)
}
const getReceivingPOStatuses = () => {
  return http.get(`/receiving-po-statuses`)
}
const getReceivingPOReasons = () => {
  return http.get(`/receiving-po-reasons`)
}
const create = (data) => {
  return http.post(`/receivings`, data)
}
const update = (id, data) => {
  return http.put(`/receivings/${id}`, data)
}
const remove = (id) => {
  return http.delete(`/receivings/${id}`)
}
const deleteItem = (id) => {
  return http.delete(`/receiving-items/${id}`)
}
const getCSVTemplate = () => {
  return http.get(`/import/receiving-structure`)
}
const importCSV = (data) => {
  return http.post(`/import/receivings`, data)
}
const addReceivingItem = (data) => {
  return http.post(`/receiving-items`, data)
}
const emailExportExcel = (param) => {
  return http.get(`/export/receivings`, { params: param })
}

const ReceivingDataService = {
  getAll,
  get,
  remove,
  getStatuses,
  getDeliveryStatuses,
  getReceivingItemReasons,
  getReceivingItemStatuses,
  getReceivingPOStatuses,
  getReceivingPOReasons,
  create,
  update,
  deleteItem,
  getCSVTemplate,
  importCSV,
  addReceivingItem,
  emailExportExcel,
}
export default ReceivingDataService
