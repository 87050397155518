import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import './i18n'
import './casl/can'
import { CAlert } from '@coreui/react-pro'
import ErrorBoundary from './components/ErrorBoundary'
import Page500 from './views/pages/page500/Page500'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./views/auth/Login'))
const ForgotPassword = React.lazy(() => import('./views/auth/ForgotPassword'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500Var = React.lazy(() => import('./views/pages/page500/Page500'))
const ResetPassword = React.lazy(() => import('./views/auth/ResetPassword'))
const UserResetPassword = React.lazy(() => import('./views/auth/UserResetPassword'))

const error = <Page500 />

class App extends Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <ErrorBoundary fallback={error}>
          <Suspense fallback={loading}>
            <Routes>
              <Route exact path="/login" name="Login Page" element={<Login />} />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500Var />} />
              <Route
                exact
                path="/forgot-password"
                name="forgot password"
                element={<ForgotPassword />}
              />
              <Route
                exact
                path="/reset-password"
                name="reset password"
                element={<ResetPassword />}
              />
              <Route
                exact
                path="/user-reset-password"
                name="user reset password"
                element={<UserResetPassword />}
              />
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </BrowserRouter>
    )
  }
}

export default App
