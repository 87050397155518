import { PICKING_METHODS_DATA } from '../actions/actionType'

const initialState = {
  pickingMethods: [],
}
export default function PickingMethodsReducer(state = initialState, action) {
  switch (action.type) {
    case PICKING_METHODS_DATA:
      return { ...state, pickingMethods: action.payload }
    default:
      return state
  }
}
