import { LOCATION_TYPE_DATA } from '../actions/actionType'

const initialState = {
  locationTypes: [],
}
export default function LocationTypeReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_TYPE_DATA:
      return { ...state, locationTypes: action.payload }
    default:
      return state
  }
}
